.bgImg {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}